$break-large: 1350px;
$break-medium: 900px;
$break-small: 450px;

.skills {
  height: 59vh;
  background-color: #282c34;
  color: white;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 0.3fr;
  font-family: "Marcellus SC", serif;

  @media (max-width: $break-small) {
    height: 47vh;
  }
  @media (max-width: $break-large) {
    height: 59vh;
  }

  a {
    color: white;
    text-decoration: underline;
    font-size: 1.5em;
    transition: 0.1s;


  }

  a:hover {
    letter-spacing: 1.2px;
    transform: scale(1.07);
    text-decoration: none;
    }

.link-container {
    bottom: 50px;
    left: 50px;
    right: 50px;
    margin-bottom: 105px;
    position: fixed;

    
    @media (max-width: $break-medium) {
        margin-bottom: 55px;
      }
    
      @media (max-width: $break-medium) and (max-height: 721px) {
        display: none;
      }
    
              
      @media (max-width: $break-small) {
        display: none;
      }
    


}

  .skills-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-content: center;
    justify-content: space-around;
    width: 60%;
    margin-top: 3%;
    height: 60%;

    @media (max-width: $break-medium) {
      padding-bottom: 5%;
    }

    @media (max-width: $break-medium) {
    flex-direction: column;
    margin-top: 0%;
    }

    .skills-list {
      height: 30vh;

      @media (max-width: $break-medium) {
        margin-bottom: 15%;
      }

      h2 {

        font-size: 1.18em;
        @media (max-width: $break-large) {
          height: 3%;
          font-size: 1.1em;
        }

        @media (max-width: $break-medium) {
          height: 2%;
          font-size: 1.0em;
        }

        @media (max-width: $break-medium) and (max-height: 721px) {
            display: none;
          }
        
          @media (max-width: $break-small) {
            display: none;
          }
      }

      .skills-holder {
        width: 200px;
        display: flex;
        align-content: center;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        font-size: 1em;
        height: 50%;

        p {
            font-size: 0.96em;
          @media (max-width: $break-large) {
            height: 2%;
            font-size: 0.95em;
          }
          @media (max-width: $break-medium) {
            height: 3%;
            font-size: 0.95em;
          }
        }
      }
    }
  }
}
