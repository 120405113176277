$break-large: 1350px;
$break-medium: 900px;
$break-small: 450px;

.about {
    height: 59vh;
    background-color: #282c34;
    color: white;
    display: block;
    min-height: 59vh;

    @media (max-width: $break-small) { 
        height: 47vh;
        min-height: 47vh;
      }


    .about-wrapper {
        padding-top: 2%;
        width: 60%;
        display: flex;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-content: center;

        
        @media (max-width: $break-large) {
            flex-direction: column-reverse;
            padding-top: 0%;
          }


        .info-container {
            font-size: 1.1em;
            font-family: 'Marcellus SC', serif;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 5% 10% 0% 10%;

            .learn-more {
                text-decoration: underline;
                color: white;
                font-size: 1.4em;
                transition: 0.1s;
                padding-top: 2%;
                
            }

            .learn-more:hover {
              text-decoration: none;
                letter-spacing: 1px;
                transform: scale(1.05);
            }
        }

        .photo {
            @media (max-width: $break-small) { 
                height: 1vh;
                max-height: 5px;
                display: none;
              }

        .selfie {
            height: 28vh;
            margin-top: 50px;
            min-height: 150px;
            align-content: center;
            margin-left: 75px;

            @media (max-width: $break-large) {
                margin-left: 0px;
                padding-bottom: 5%;
              }

              @media (max-width: $break-small) { 
                height: 1vh;
                max-height: 5px;
                display: none;
              }

              @media (max-width: $break-medium) and (max-height: 744px) {
                display: none;
              }
            
            }
    }
    }

}