$break-large: 1350px;
$break-medium: 900px;
$break-small: 450px;

.homepage {
    background-color: #282c34;
    height: 25vh;
    color: white;
    overflow: hidden;
    display: block;
    min-height: 200px;

    .name-wrapper {
        width: 65%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Lexend Exa', sans-serif;
        display: block;

        @media (max-width: $break-small) {
          width: 75%;
        }
    .name-container1 {
        font-size: 5em;
        display: flex;
        justify-content: flex-start;
        
        @media (max-width: $break-medium) {
            justify-content: space-between;
          }

        .letter {
            width: 8vw;

            @media (max-width: $break-large) {
                width: 12vw;
              }

              @media (max-width: $break-medium) {
                width: 15vw;
              }
        }
    }
    .name-container2 {
        font-size: 5em;
        display: flex;
        color: #282c34;
        background-color: white;
        justify-content: flex-end;

        @media (max-width: $break-medium) {
            justify-content: space-between;
          }

        .letter {
            width: 8vw;

            @media (max-width: $break-large) {
                width: 12vw;
              }
            
              @media (max-width: $break-medium) {
                width: 15vw;
              }
        }

    }
}
}