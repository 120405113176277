$break-large: 1600px;
$break-medium: 900px;
$break-small: 450px;

.project-item {
  width: 95vw;
  color: white;
  min-width: 30%;
  height: 450px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 25px;
  padding-bottom: 30px;
  overflow: hidden;

  @media (max-width: $break-medium) {
    height: auto;
   }



  .project-wrapper {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    padding-left: 100px;
    padding-right: 100px;

    @media (max-width: $break-large) {
      padding-top: 200px;
        flex-direction: column-reverse;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }

    .image-wrapper {
      width: 35%;
      margin: auto;
       display: flex;
       justify-content: center;
       align-content: space-between;

       .project-image {
         max-width: 500px;
         max-height: 500px;
       }

       &:hover {
        cursor: pointer;
      }

    @media (max-width: $break-large) {
        width: 50%;
      }
    margin: auto;
      .background-image {
        pointer-events: none;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: auto;
        width: calc(350px + 5%);
        height: 350px;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
      }
    }

    .desc-text {
      width: 45%;
      margin: auto;
      font-family: "Marcellus SC", serif;
      display: flex;
      justify-content: center;
      align-content: space-between;
      flex-direction: column;

      @media (max-width: $break-medium) {
      padding-top: 50px;
       flex: 1;
       padding-bottom: 15px;

      }
      .title {

        @media (max-width: $break-medium) {
         padding: 10px;
          }
      }
      .description {


        @media (max-width: $break-medium) {

           }
      }

      .git-deploy-wrapper {
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;

        @media (max-width: $break-medium) {
          padding-top: 2px;
           }

        .deploy {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 75px;
          width: 75px;
  
        .deploy-img {
          height: 45%;
          width: 45%;

          &:hover {
            cursor: pointer;
          }
        }
        }

      .github {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        width: 75px;

      .github-img {
        height: 60%;
        width: 60%;

        &:hover {
          cursor: pointer;
        }
      }
      }
    }
    }
  }
}
