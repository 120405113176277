$break-medium: 900px;
$break-small: 450px;

.header {
    height: 8vh;
    min-height: 39px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #282c34;
    align-items: center;
    padding-right: 10px;

    @media (max-width: $break-small) { 
      height: 20vh;
      padding-top: 7px;
      padding-right: 0px;
    }



    @media (max-width: $break-medium) {
      margin-left: auto;
      margin-right: auto;
      justify-content: space-around;
    }
  
    .options {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: $break-small) { 
        flex-direction: column;
        padding-top: 3%;
      }

      @media (max-width: $break-medium) {
        margin-left: auto;
        margin-right: auto;
        justify-content: space-around;
      }


         .option {
        width: 125px;
        padding: 10px 15px;
        cursor: pointer;
        text-decoration: none;
        color: white;
        transition: 0.1s;

        @media (max-width: $break-medium) {
          min-width: 23vw;
        }

        &.bold {
          letter-spacing: 2px;
          transform: scale(1.05);
        }
      }

      .option:hover {
        letter-spacing: 2px;
        transform: scale(1.05);

        @media (max-width: $break-medium) {
          letter-spacing: 0px;
          transform: scale(1.05);
        }
    }
  }
}
  